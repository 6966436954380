import React, { Component } from 'react';
import './PrivacyHeader.css';

class PrivacyHeader extends Component {
  render() {
    return (
      <div className="PrivacyHeader">
        <div className="background">
          <div className="contents">
            <span className="desc">개인정보처리방침</span>
            <span className="title">LEMONCAKE</span>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyHeader;