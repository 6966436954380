import React, { Component } from 'react';
import YouTubeThumbnail from 'youtube-thumbnail';
import './YoutubeThumbCell.css';

class YoutubeThumbCell extends Component {
  
  render() {
    //var youtubeThumbnail = require('youtube-thumbnail');
    var thumbnail = 'https://img.youtube.com/vi/' + this.props.data.videoId + '/mqdefault.jpg';  
    const title = {
      margin: "10px",
      fontFamily: "NanumBarunGothicBold",
      fontSize: "16px",
      color: "white",
      textDecoration: "none",
      listStyle: "none",
      textAlign:"center"
    };

    const subtitle = {
      margin: "10px",
      fontFamily: "NanumBarunGothic",
      fontSize: "13px",
      color: "#a0a0a0",
      textDecoration: "none",
      listStyle: "none",
      textAlign:"center"
    };

    return (
      <div className="YoutubeThumbCell" onClick={function() { this.props.onSelect(this.props.data); }.bind(this)}>
        <img className="thumb" src={thumbnail} alt="prop"/> 
        <div className="info">
          <ul>
            <li style={title}>{this.props.data.title}</li>
            <hr className='seperator'/>
            <li style={subtitle}>{this.props.data.desc}</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default YoutubeThumbCell;