import React, { Component } from 'react';
import './Privacy.css';
import Menu from '../main/Menu'
import PrivacyHeader from './PrivacyHeader'
import PrivacyTerms from './PrivacyTerms'

class Privacy extends Component {
  render() {
    return (
      <div className="Privacy">
        <header>
          <a href="/" className="title"><h1>LEMONCAKE</h1></a>
          <Menu/>
        </header>
        <PrivacyHeader/>
        <PrivacyTerms/>
        <footer>
          <div>
            <span className="ci">LEMONCAKE</span>
            <div className="ci" style={{width:"40px"}}/>
            <span className="copyrights">Copyright ©2020 LEMONCAKE All Rights Reserved.</span>
            <div style={{width:"40px"}}>
              <hr style={{border: "solid 1px #222222", height:"10px"}}/>
            </div>
            <a href="/privacy" className="privacy">개인정보처리방침</a>
          </div>
        </footer>
      </div>
    );
  }
}

export default Privacy;