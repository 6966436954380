import React, { Component } from 'react';
import logo from '../assets/images/soso_ci.png';
import app_store from '../assets/images/ico_appstore.png';
import aos_store from '../assets/images/ico_playstore.png';

import './Header.css';

class Header extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <div>
            <div className="store">
              <div><img className="img" src={app_store} alt="APPSTORE" onClick={function() {
                window.open('itms-apps://itunes.apple.com/app/id1582049413', '_blank').focus();
              }}/></div>
              <div><img className="img" src={aos_store} alt="GOOGLE PLAYSTORE" onClick={function() {
                window.open('market://details?id=com.lemoncake.sosofix', '_blank').focus();
              }}/></div>
            </div>
            <p className="content"><strong>소소</strong>한 집수리</p>
            <p className="subcontent">우리 동네 집수리 커뮤니티</p>
          </div>
          <div style={{margin:"2vmin"}}/>
          <div>
            <img src={logo} className="App-logo" alt="logo" />  
          </div>
        </div>
      </div>
    );
  }
}

export default Header;