import React, { Component } from 'react';
import './PromoPage.css';
import YoutubeThumbCell from './YoutubeThumbCell';
import YoutubeLogo from "../assets/images/YouTube_full-color_icon_(2017).svg"
import MoveUp from "../assets/images/btn_move_up.png"
import MoveDown from "../assets/images/btn_move_down.png"

class PromoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scroll : 0,
      scrollOffset : 0,
      screenWidth: window.innerWidth,
      anim : true,
      scrolling : false,
      currentVidId : "afHQRddBMcE"
    }

    this.videoData = [
      {
        videoId:"afHQRddBMcE",
        title:"EP.01 수리 신청 방법",
        desc:"Created By SOSO-FIX"
      },
      {
        videoId:"M3EO0VPdPls",
        title:"EP.02 가게 홍보 방법",
        desc:"Created By SOSO-FIX"
      },
      {
        videoId:"afHQRddBMcE",
        title:"EP.01 수리 신청 방법",
        desc:"Created By SOSO-FIX"
      },
      {
        videoId:"M3EO0VPdPls",
        title:"EP.02 가게 홍보 방법",
        desc:"Created By SOSO-FIX"
      },
      {
        videoId:"afHQRddBMcE",
        title:"EP.01 수리 신청 방법",
        desc:"Created By SOSO-FIX"
      },
      {
        videoId:"M3EO0VPdPls",
        title:"EP.02 가게 홍보 방법",
        desc:"Created By SOSO-FIX"
      },
    ];

    this.state.scrollOffset = this.videoData.length - 1;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    
    this.selectPage   = this.selectPage.bind(this);
    this.pageUp       = this.pageUp.bind(this);
    this.pageDown     = this.pageDown.bind(this);
  }

  render() {
    var width = 800;
    if (this.state.screenWidth < 800) {
      width = this.state.screenWidth;
    }
    var height = width / 16 * 9;

    var transition = "none";
    if (this.state.anim) {
      transition = "all 0.2s ease 0s";
    }
    
    if (this.state.screenWidth < 800) {
      return (
        <div className="PromoPageBackground">
          <div className="PromoPage">
            <div className="Inner">
              <div className="VideoPanel">
                <div className="title">WHAT IS SOSO?</div>
                <div style={{height:"10px"}}/>
                <iframe 
                  width={width} height={height} src={"https://www.youtube.com/embed/" + this.state.currentVidId}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen/>
                  <div style={{height:"10px"}}/>
                <a href="#;"><img style={{width:'20px', marginRight:'4px'}} src={YoutubeLogo}/> 채널 바로가기</a>
              </div>
              <div style={{height:"80px"}}/>
              <div>
              <div style={{display:"flex", justifyContent:"space-between"}}><hr className="light" style={{width:'40%'}}/><hr className="light" style={{width:'40%'}}/></div>
                  <div className="scroll_panel">
                    <button style={{cursor:"pointer", backgroundColor:"transparent", border: "none", position:'relative', top:"-24px"}} onClick={this.pageUp}>
                      <img src={MoveUp}/>
                    </button>
                    <div className="clipping_panel">
                      <div className="scroll_view" style={{top:this.state.scroll - 110, WebkitTransition:transition}}>
                        <YoutubeThumbCell data={this.videoData[this.state.scrollOffset]} onSelect={this.selectPage}/>
                        <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 1) % this.videoData.length]} onSelect={this.selectPage}/>
                        <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 2) % this.videoData.length]} onSelect={this.selectPage}/>
                        <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 3) % this.videoData.length]} onSelect={this.selectPage}/>
                        <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 4) % this.videoData.length]} onSelect={this.selectPage}/>
                        <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 5) % this.videoData.length]} onSelect={this.selectPage}/>
                      </div>
                    </div>
                    <button style={{cursor:"pointer", backgroundColor:"transparent", border: "none", position:'relative', bottom:"-28px"}} onClick={this.pageDown}>
                      <img src={MoveDown}/>
                    </button>
                  </div>
                  <div style={{display:"flex", justifyContent:"space-between"}}><hr className="light" style={{width:'40%'}}/><hr className="light" style={{width:'40%'}}/></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
      <div className="PromoPageBackground">
        <div className="PromoPage">
          <div className="Inner">
            <div className="title">WHAT IS SOSO?</div><div/>
            <div>
            <iframe 
                  width={width} height={height} src={"https://www.youtube.com/embed/" + this.state.currentVidId}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen/>
            </div>
            <div>            
              <div style={{display:"flex", justifyContent:"space-between"}}><hr className="light" style={{width:'40%'}}/><hr className="light" style={{width:'40%'}}/></div>
              <div className="scroll_panel" style={{height:height}}>
                <button style={{backgroundColor:"transparent", border: "none", position:'relative', top:"-24px"}} onClick={this.pageUp}>
                  <img src={MoveUp}/>
                </button>
                <div className="clipping_panel">
                  <div className="scroll_view" style={{top:this.state.scroll - 110, WebkitTransition:transition}}>
                    <YoutubeThumbCell data={this.videoData[this.state.scrollOffset]} onSelect={this.selectPage}/>
                    <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 1) % this.videoData.length]} onSelect={this.selectPage}/>
                    <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 2) % this.videoData.length]} onSelect={this.selectPage}/>
                    <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 3) % this.videoData.length]} onSelect={this.selectPage}/>
                    <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 4) % this.videoData.length]} onSelect={this.selectPage}/>
                    <YoutubeThumbCell data={this.videoData[(this.state.scrollOffset + 5) % this.videoData.length]} onSelect={this.selectPage}/>
                  </div>
                </div>
                <button style={{backgroundColor:"transparent", border: "none", position:'relative', bottom:"-28px"}} onClick={this.pageDown}>
                  <img src={MoveDown}/>
                </button>
              </div>
              <div style={{display:"flex", justifyContent:"space-between"}}><hr className="light" style={{width:'40%'}}/><hr className="light" style={{width:'40%'}}/></div>
            </div>
            <div><a href="https://www.youtube.com/channel/UCSHb4kO-Xv-j3wIcagAzgpA"><img style={{width:'20px', marginRight:'4px'}} src={YoutubeLogo}/> 채널 바로가기</a></div><div/>
          </div>
        </div>
      </div>
      );
    }
  }

  async pageDown() {
    if (this.state.scrolling)
      return;

    const current = this.state.scroll - 110
    this.setState({scroll: current, scrolling: true})

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300);

    const offsetPage = (this.state.scrollOffset - current / 110) % this.videoData.length;
    this.setState({anim: false, scrollOffset: offsetPage, scroll: 0});
    await sleep(100);

    this.setState({anim: true, scrolling: false});
  }

  async pageUp() {
    if (this.state.scrolling)
      return;

    const current = this.state.scroll + 110
    this.setState({scroll: current, scrolling: true})

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300);

    var offsetPage = (this.state.scrollOffset - current / 110);
    if (offsetPage < 0)
      offsetPage = this.videoData.length - 1;

    this.setState({anim: false, scrollOffset: offsetPage, scroll: 0});
    await sleep(100);
    
    this.setState({anim: true, scrolling: false});
  }

  selectPage(video) {
    this.setState({currentVidId: video.videoId})
    console.log(video.videoId);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

}

export default PromoPage;


/*
<YouTube videoId="xdKOQEIC9_k" opts={{
              width: "560",
              height: "315",
              playerVars: {
                autoplay: 0, //자동재생 O
                rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
              }}} onEnd={(e)=>{e.target.stopVideo(0);}}/>*/