import React, { Component, useEffect } from 'react';
import './ContactForms.css';
import { format } from 'react-string-format';

let privacy_terms = "LEMONCAKE 에서는 소소한집수리 서비스와 관련하여 제휴를 희망하는 기업 등을 대상으로 아래와 같이 개인정보를 수집합니다. 수집한 개인정보는 제휴 제안 검토를 위해서만 이용되며, ‘개인정보보호법’ 등 관계 법령에 의하여 안전하게 관리됩니다. 아래의 개인정보 수집은 제휴 제안 검토 및 담당자 연락을 위한 기본적인 내용으로 이를 거부하실 경우, 본 제휴 제안 등록이 불가능합니다."
let categories = ["업체등록", "투자관련", "기타문의"];

class ContactForms extends Component {

  constructor(props) {
    super(props)

    this.state = {
      remain: 3,
      response: '',
    
      category: "",
      title: "",
      content: "",

      // privacy info
      orgName: "",
      manager: "",
      phone: "",
      email: "",
      siteUrl: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    //this.onEditChanged = this.onEditChanged.bind(this);
    this.onCategoryChanged = this.onCategoryChanged.bind(this);

    
  }

  render() {
    if (this.state.response == "ok") 
      return this.afterRender();
    
    return this.beforeRender();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  afterRender() {
    return (
      <div className="body">
          <div className="complete">
            제안이 정상적으로 접수 되었습니다. 신속히 검토하여 답변 드리도록 하겠습니다.
          </div>
          <span style={{color:"gray"}}>{this.state.remain}초 후에 홈으로 이동합니다.</span>
      </div>
    );
  }
  beforeRender() {
    return (
      <div className="ContactTerms">
        <div className="header">
          <ul>
            <li className="notice">등록된 제휴 제안은 LEMONCAKE 제휴 검토 목적으로만 이용됩니다.</li>
            <li className="notice">제안서 접수 완료 및 검토 결과는 입력한 이메일로 발송됩니다.</li>
            <li className="notice">등록된 제휴 제안은 신속하게 검토되지만, 일부 사안의 경우 검토 기간이 다소 길어질 수 있습니다.</li>
          </ul>
        </div>
        <div style={{margin:"30px"}}/>
        <div className="body">
          <div style={{width:"100%"}}>
            <div className="header"><span className="section_title">제휴 제안 내용</span><span className="section_content">* 필수 항목</span></div>
            <hr className='light' style={{marginTop:"10px", borderColor:"#eee", marginBottom:"45px"}}/>

            {/* 카테고리 */}
            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>제휴 카테고리<span style={{fontSize:"13px", color:"red"}}>*</span></div>
              <div style={{flexGrow:"1"}}>
                <div id="dropdown_box" className="collapse">
                  <button id="category_box" className="form_drop" onClick={this.onToggle}>{this.state.category}</button>
                  <div className="drop_menu">
                    <button id="0" className="drop_item" onClick={this.onCategoryChanged}>{categories[0]}</button>
                    <button id="1" className="drop_item" onClick={this.onCategoryChanged}>{categories[1]}</button>
                    <button id="2" className="drop_item" onClick={this.onCategoryChanged}>{categories[2]}</button>
                  </div>
                </div>
                <span id="err_category" className="hidden">*카테고리를 선택해 주세요.</span>
              </div>
            </div>

            <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"45px"}}/>

            {/* 문서 제목 */}
            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>제목<span style={{fontSize:"13px", color:"red"}}>*</span></div>
              <div style={{flexGrow:"1"}}>
                <input type="text" id="title" className="form_box" maxLength="101" onChange={this.onEditChanged.bind(this, 100, "title")}/>
                <span id="err_title" className="hidden">*제목을 입력해 주세요.</span>
                <span id="err_title_length" className="hidden">*제목은 최대길이 100 글자를 넘길 수 없습니다.</span>
              </div>
            </div>

            <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"45px"}}/>

            {/* 문서 내용 */}
            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>내용<span style={{fontSize:"13px", color:"red"}}>*</span></div>
              <div style={{flexGrow:"1"}}>
                <textarea id="content" className="form_content" maxLength="4001" onChange={this.onEditChanged.bind(this, 4000, "content")}/>
                <span id="err_content" className="hidden">*내용을 입력해 주세요.</span>
                <span id="err_content_length" className="hidden">*내용은 최대길이 4000 글자를 넘길 수 없습니다.</span>
              </div>
              
            </div>
          </div>

          <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"45px"}}/>

          <div style={{width:"100%"}}>
            <div className="header"><span className="section_title">제휴 제안자 정보</span><span className="section_subtitle">* 필수 항목</span></div>
            <hr className='light' style={{marginTop:"10px", borderColor:"#eee", marginBottom:"45px"}}/>
            
            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>회사(기관)명<span style={{fontSize:"13px", color:"red"}}>*</span></div>
              <div style={{flexGrow:"1"}}>
                <input type="text" id="orgName" className="form_box" maxLength="50" onChange={this.onEditChanged.bind(this, 50, "orgName")}/>
                <span id="err_orgName" className="hidden">*회사(기관)명을 입력해 주세요.</span>
              </div>
            </div>

            <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"45px"}}/>

            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>성명<span style={{fontSize:"13px", color:"red"}}>*</span></div>
              <div style={{flexGrow:"1"}}>
                <input type="text" id="manager" className="form_box" maxLength="20" placeholder='담당자 이름을 입력해 주세요.' onChange={this.onEditChanged.bind(this, 20, "manager")}/>
                <span id="err_manager" className="hidden">*성명(이름)을 입력해 주세요.</span>
              </div>
            </div>

            <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"45px"}}/>

            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>전화번호<span style={{fontSize:"13px", color:"red"}}>*</span></div>
              <div style={{flexGrow:"1"}}>
                <input type="text" id="phone" className="form_box" maxLength="20" placeholder='번호만 입력해 주세요.' onInput={this.onNumberChanged} onChange={this.onEditChanged.bind(this, 20, "phone")}/>
                <span id="err_phone" className="hidden">*전화번호를 입력해 주세요.</span>
              </div>
            </div>

            <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"45px"}}/>

            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>이메일<span style={{fontSize:"13px", color:"red"}}>*</span></div>
              <div style={{flexGrow:"1"}}>
                <input type="email" id="email" className="form_box" maxLength="50" onChange={this.onEditChanged.bind(this, 50, "email")}/>
                <span id="err_email" className="hidden">*이메일을 입력해 주세요.</span>
              </div>
            </div>

            <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"45px"}}/>

            <div style={{display:"flex", alignItems:"top"}}>
              <div style={{width:"30%", maxWidth:"220px"}}>홈페이지</div>
              <div style={{flexGrow:"1"}}>
                <input type="text" id="siteUrl" className="form_box" placeholder='홈페이지가 있는 경우만 입력해 주세요.' maxLength="1024" onChange={this.onEditChanged.bind(this, 1024, "siteUrl")}/></div>
            </div>

            <hr className='light' style={{marginTop:"45px", borderColor:"#eee", marginBottom:"65px"}}/>

            <PrivacyTerms onSelectChanged={function(e) { 
              if (e.target.checked) document.getElementById("err_privacy").className = "hidden";
            }.bind(this)}/>
            <div style={{margin:"5vmin"}}/>
            <NoticeTerms onSelectChanged={function(e) { 
              if (e.target.checked) document.getElementById("err_notice").className = "hidden";
            }.bind(this)}/>

            <div className="action_panel">
              <span id="cancel" className="action" onClick={function() {   }}>취소</span>
              <div style={{width:"30px"}}></div>
              <div id="ok" className="action" onClick={this.onSubmit}>  
                <div id="register">제안 등록</div>
                <div id="registering" className="hidden">
                  <div className="spinner"/>등록중..
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onToggle() {
    var obj = document.getElementById("dropdown_box");  
    if (obj.className == "collapse") 
      obj.className = "expand";
    else obj.className = "collapse";
  }

  onCategoryChanged(e) {
    // collapsing
    document.getElementById("dropdown_box").className = "collapse";

    //
    this.setState({category:categories[parseInt(e.target.id)]});
  }

  onNumberChanged(e) {
    var s = e.target.value;
    s = s.replace(/[^0-9\.]+/g, "");
    e.target.value = s;
  }

  onEditChanged(maxLength, stateName, e) {
    if (maxLength > 0) {
      var errorMsg = document.getElementById("err_" + stateName + "_length"); 
      if (errorMsg != null) {
        if (e.target.value.length > maxLength) {
          errorMsg.className = "error";
          return;
        } else errorMsg.className = "hidden";
      }
    }
    this.setState({[stateName]: e.target.value});
  }

  static checkValidation(value) {
    if (value == null)
      return false;

    if (typeof value !== 'string') 
      return value >= 0;
    
    return value.trim().length > 0;
  }

  async onSubmit() { 
    var fieldIds = ["category", 
                    "title", 
                    "content", 
                    "orgName", 
                    "manager", 
                    "phone", 
                    "email"];
    
    var emptyField = false;
    for (var i = 0 ; i < fieldIds.length ; i++) {
      var fieldValue = this.state[fieldIds[i]]; 
      var fieldError = document.getElementById("err_" + fieldIds[i]); 
      
      var errorMsg = document.getElementById("err_" + fieldIds[i] + "_length"); 
      if (errorMsg != null)
        errorMsg.className = "hidden";

      if (ContactForms.checkValidation(fieldValue)) 
        fieldError.className = "hidden";
      else {
        fieldError.className = "error";
        emptyField = true;
      }
    }

    var checkTerms = ["privacy", "notice"];
    for (var i = 0 ; i < checkTerms.length ; i++) {
      var checkNotice = document.getElementById(checkTerms[i]); 
      var noticeError = document.getElementById("err_" + checkTerms[i]); 

      if (checkNotice.checked) 
        noticeError.className = "hidden";
      else {
        noticeError.className = "error";
        emptyField = true;
      }
    }

    if (emptyField)
      return;

    document.getElementById("register").className = "hidden";
    document.getElementById("registering").className = "sending";
    
    try {
      let res = await fetch("/register_alliance.php", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          'Content-Type' : 'application/json',
          'Accept-Charset' : 'UTF-8',
          'User-Agent' : 'lemoncake-soso/1.0.0'
        }
      });
      
      let resJson = await res.json();
      if (resJson.resCode == 200) {
        this.setState({response:"ok"});

        this.interval = setInterval(() => {
          if (this.state.remain <= 0) {
            clearInterval(this.interval);
            window.open('/', "_self");
          }
          else this.setState({remain:this.state.remain - 1});
        }, 1000);
      }
      else throw resJson.error;

    } catch(err) {
      alert("등록중 장애가 발생했습니다. 에러=[" + err + "]");
      console.log(err);
      document.getElementById("register").className = "";
        
    } finally {
      document.getElementById("registering").className = "hidden";
    }
  }
}

function PrivacyTerms(props) {
  return (
            <div>
              <span style={{fontFamily:"NanumBarunGothicBold",  fontSize:"1.1em"}}>개인정보 수집 및 이용에 대한 안내</span>
              <div className="terms">
                <div style={{margin:"20px"}}>{privacy_terms}</div>
              </div>
              <div className="check_area">
                <label className="contact">개인정보 수집 및 이용에 대해서 동의합니다.
                  <input id="privacy" type="checkbox" onChange={function(e) {props.onSelectChanged(e);}} />
                  <span className="checkmark"/>
                </label>
                <span id="err_privacy" className="hidden">*개인정보 수집 및 이용 항목을 확인해 주세요.</span>
              </div>
            </div>
  );
}

function NoticeTerms(props) {
  return (
            <div>
              <span style={{fontFamily:"NanumBarunGothicBold", fontSize:"1.1em"}}>주의 사항</span>
              <div className="terms">
                <div style={{margin:"20px"}}>{privacy_terms}</div>
              </div>
              <div className="check_area">
                <label className="contact">상기 주의 사항을 확인하였으며 이에 동의합니다.
                  <input id="notice" type="checkbox" onChange={function(e) {props.onSelectChanged(e);}}/>
                  <span className="checkmark"/>
                </label>
                <span id="err_notice" className="hidden">*주의 사항 항목을 확인해 주세요.</span>
              </div>
            </div>
  );
}

export default ContactForms;