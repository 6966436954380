import React, { Component } from 'react';
import './ContactHeader.css';

class ContactHeader extends Component {
  render() {
    return (
      <div className="ContactHeader">
        <div className="background">
          <div className="contents">
            <span className="desc">CONTACT US</span>
            <span className="title">&nbsp;제휴 제안</span>
          </div>
          
        </div>
      </div>
    );
  }
}

export default ContactHeader;