import React, { Component } from 'react';
import './Contact.css';
import Menu from '../main/Menu'
import ContactHeader from './ContactHeader'
import ContactForms from './ContactForms'

class Contact extends Component {
  render() {
    return (
      <div className="Contact">
        <header>
          <a href="/" className="title"><h1>LEMONCAKE</h1></a>
          <Menu/>
        </header>
        <ContactHeader/>
        <div style={{height:"40px"}}/>
        <ContactForms/>
        <footer>
          <div>
            <span className="ci">LEMONCAKE</span>
            <div className="ci" style={{width:"40px"}}/>
            <span className="copyrights">Copyright ©2020 LEMONCAKE All Rights Reserved.</span>
            <div style={{width:"40px"}}>
              <hr style={{border: "solid 1px #222222", height:"10px"}}/>
            </div>
            <a href="/privacy" className="privacy">개인정보처리방침</a>
          </div>
        </footer>
      </div>
    );
  }
}

export default Contact;