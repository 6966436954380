import React, { Component } from 'react';
import './FunctionCell.css';

class FunctionCell extends Component {
  
  render() {
    return (
      <div className="FunctionCell" onClick={function() { this.props.onSelect(this.props.data); }.bind(this)}>
        <img className="thumb" style={{maxHeight:"72px", height:"12vw"}} src={this.props.data.icon} alt="prop"/>  
        <div className="title">{this.props.data.title}</div>
      </div>
    );
  }
}

export default FunctionCell;