import React, { Component } from 'react';
import './PrivacyTerms.css';

class PrivacyTerms extends Component {
  render() {
    return (
      <div className="PrivacyTerms">
        <div className="contents">
          <h1 style={{alignSelf:"center", fontFamily:"NanumBarunGothicBold"}}>개인정보처리방침</h1><br/>
          <span id="section1">레몬케이크(이하 “회사”)는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련된 고충을 신속하고 원활하게 처리하기 위하여 다음과 같이 개인정보 처리방침을 운영합니다.</span>
          <br/>
          <span className="first">제1조 (개인정보 처리목적)</span><br/>
          <span className="second">① 회사는 다음의 목적을 위하여 개인정보를 수집합니다. 수집한 개인정보는 다음 목적 이외의 용도로 이용되지 않으며, 이용 목적이 변경되는 경우에는 정보주체의 별도의 동의를 받는 등 필요한 조치를 취할 예정입니다.</span>
          <ul>
            <li className="third">회원 가입 및 관리: 본인 식별  인증, 가입 연령 확인, 회원 자격 유지 · 제한 · 상실 등, 서비스 부정이용 방지, 각종 통지 및 고지</li>
            <li className="third">서비스의 제공: 매칭 서비스, 커뮤니티 서비스, 정보 공유 서비스, 로그인 유지, 앱 보안, 앱 업데이트 알림, 그 밖의 부가서비스 등</li>
            <li className="third">고충 및 분쟁 처리: 신원확인, 고충 또는 분쟁사항 확인, 연락  통지(사실확인, 처리결과 등)</li>
          </ul>
          <br/>
          <span className="second">② 회사는 다음과 같은 방법으로 개인정보를 수집합니다.</span>
          <ul>
            <li className="third">정보주체의 동의를 받는 방법</li>
            <li className="third">다른 SNS 매체로부터 제공받는 방법</li>
            <li className="third">회사는 다음의 경우 정보주체의 동의 없이 개인정보를 수집 및 이용할 수 있습니다.</li>
          </ul>
          <span className="fourth">가. 회사는 다음의 경우 정보주체의 동의 없이 개인정보를 수집 및 이용할 수 있습니다.</span>
          <span className="fourth">나. 서비스 이용 과정에서 불가피하게 생성되는 개인정보[서비스 이용기록(앱 접속 및 이용기록), IP / Mac 주소, OS Type, OS Version 등</span>
          <br/><br/>
          <span className="first">제2조 (처리하는 개인정보 항목)</span><br/>
          <span className="second">회사가 수집 및 이용하는 개인정보의 항목은 다음과 같습니다.</span>
          <br/>
          <table style={{alignSelf:"center", borderRadius:"6px", border:"1px solid #36a49e", textAlign:"center"}}>
            <tr style={{fontWeight:"bold", height:"50px"}}>
              <td style={{width:"30%"}}>수집 목적</td><td style={{width:"60%"}}>수집 항목</td></tr>
            <tr style={{height:"80px"}}><td>회원 가입 및 관리</td><td>필수: 닉네임, 이메일 주소, 연락처<br/>선택: 성별</td></tr>
            <tr style={{height:"80px"}}><td>서비스 제공</td><td>필수: 활동지역(동까지 표시), IP/Mac address, OS Type, OS Version, 접속위치 등<br/>선택: 닉네임</td></tr>
            <tr style={{height:"80px"}}><td>회원 고충 및 불만 처리</td><td>이름, 이메일 주소, 연락처, 고충 및 불만 내용</td></tr>
            <tr style={{height:"80px"}}><td>서비스 이용과정에서 자동 생성 및 수집</td><td>서비스 이용기록(앱 접속 및 이용기록), IP / Mac 주소, OS Type, OS Version 등</td></tr>
          </table>
          <br/><br/>
          <span className="first">제3조 (개인정보의 보유 및 이용 기간)</span><br/>
          <span className="second">① 회사는 정보주체의 개인정보를 수집할 때 동의 받은 개인정보 보유 및 이용기간 또는 법령에 따른 개인정보 보유 및 이용기간 내에서 개인정보를 처리합니다.</span>
          <span className="second">② 회사에서 처리하는 개인정보의 보유 및 이용기간은 다음과 같습니다.</span>
          <ul>
            <li className="third">회원 가입 • 관리 / 서비스 제공 관련 개인정보: 정보주체의 회원 탈퇴 후 1년까지. 다만 다음의 경우 최소한 해당 기간까지 보유합니다.</li>
            <span className="fourth">가. 정보주체의 불만 또는 분쟁 처리에 관한 기록: 「전자상거래 등에서의 소비자 보호에 관한 법률(이하 “전자상거래법”)」에 따라 최소한 3년 동안 보유.</span><br/>
            <span className="fourth">나. 정보주체의 컴퓨터 통신기록, 앱 접속(log) 및 이용기록 및 IP/Mac 주소 등 정보통신기록: 「통신비밀보호법」에 따라 최소한 3개월 동안 보유.</span><br/>
            <li className="third">고충 및 분쟁처리 관련 개인정보: 해당 고충 또는 분쟁처리시까지. 다만 처리 과정 및 결과에 관한 기록은 「전자상거래법」에 따라 최소한 3년 동안 보유.</li>
          </ul>
          <br/><br/>
          <span className="first">제4조 (정보주체의 권리 • 의무 및 행사방법)</span><br/>
          <span className="second">①	정보주체는 회사에 대하여 언제든지 다음 각 호의 권리를 행사할 수 있습니다.</span>
          <span className="third">
            <ul>
              <li>개인정보 열람요구. 다만, 법률에 따라 열람이 금지되거나 제한되는 경우, 다른 사람의 생명 • 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우에는 열람을 제한하거나 거절할 수 있습니다.</li>
              <li>개인정보 정정 또는 삭제 요구. 다만, 다른 법령에서 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
              <li>개인정보 처리정지 요구. 다만 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우, 다른 사람의 생명 · 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우, 개인정보를 처리하지 아니하면 약정한 서비스를 제공하지 못하는 등 정보주체와의 계약 이행이 곤란한 경우로서 정보주체가 서비스 이용계약 해지 의사를 명확하게 밝히지 아니한 경우에는 처리정지 요구를 거절할 수 있습니다.</li>
              <li>개인정보 수집  이용  제공 동의 철회.</li>
            </ul>
          </span>
          <br/><br/>
          <span className="first">제5조 (개인정보의 파기)</span><br/>
          <span className="second">① 회사는 개인정보 보유기간의 경과, 처리목적의 달성, 사업의 종료 등 정보주체의 개인정보가 불필요하게 되었을 때에는 지체 없이 개인정보를 파기합니다.</span>
          <span className="second">② 제1항에도 불구하고 다른 법령에 따라 개인정보를 보존하여야 하는 경우에는, 해당 개인정보를 다른 개인정보와 분리하여 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 저장 • 관리합니다.</span>
          <span className="second">③ 회사는 서비스를 1년 동안 이용하지 아니하는 정보주체의 개인정보를 즉시 파기하거나 다른 정보주체의 개인정보와 분리하여 별도로 저장  관리하고, 분리 보관된 개인정보는 4년간 보관 후 지체 없이 파기합니다. 다만, 다른 법령에서 보관기간을 달리 정하고 있거나, 해당 정보주체의 요청이 있는 경우에는 예외로 합니다.</span>
          <span className="second">④ 제3항 본문의 경우, 회사는 다음 각호의 정보를 기간 만료 30일 전까지 해당 정보주체에게 통지합니다.</span>
          <span className="third">
            <ol>
              <li>개인정보를 파기하는 경우: 파기사실, 기간 만료일, 파기되는 개인정보 항목</li>
              <li>개인정보를 분리 보관하는 경우: 분리 보관 사실, 기간 만료일, 분리 보관되는 개인정보 항목</li>
            </ol>
          </span>
          <span className="second">⑤ 개인정보의 파기 절차 및 방법은 다음과 같습니다.</span>
          <span className="third">
            <ol>
              <li>파기 절차</li>
              <span style={{color: "#555555"}} className="third">회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 책임하에 개인정보를 파기합니다.</span>
              <li>파기 방법</li>
              <span style={{color: "#555555"}} className="third">전자적 파일 형태로 기록 • 저장된 개인정보는, 복원이 불가능한 방법으로 영구 삭제(초기화 또는 덮어쓰기 수행)하거나 개인정보를 삭제한 후 복구 및 재생되지 않도록 관리 · 감독</span>
              <span style={{color: "#555555"}} className="third">종이 문서나 그 밖의 기록매체에 기록 • 저장된 개인정보는, 파쇄 또는 소각하거나해당 부분을 마스킹, 천공</span>
            </ol>
          </span>
          <br/><br/>
          <span className="first">제6조 (개인정보의 안전성 확보조치)</span><br/>
          <span className="second">회사는 정보주체의 개인정보에 대한 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.</span>
          <span className="third">
            <ol>
              <li>개인정보 보호를 위한 내부 관리계획의 수립 • 시행: 개인정보 보호책임자의 지정, 개인정보취급자에 대한 정기적인 교육 시행, 개인정보의 보호조치 이행 여부의 내부 점검</li>
              <li>개인정보처리시스템에 대한 접근 통제 조치: 개인정보 보호책임자 또는 개인정보취급자에게 접근 권한 부여, 개인정보처리시스템 및 업무용 컴퓨터와 모바일 기기에 개인정보 유출방지 조치, 개인정보처리시스템 접속기록 관리, 개인정보 저장  전송 시 암호화, 악성프로그램 감영 방지를 위한 보안프로그램의 설치</li>
              <li>물리적 접근 조치: 개인정보의 안전한 보관을 위한 보관장소 또는 장치의 마련, 개인정보가 포함된 서류 또는 보조저장매체 등을 잠금 장치가 있는 곳에 보관, 개인정보가 포함된 보조저장매체의 반출 • 입 통제</li>
            </ol>
          </span>
          <br/><br/>
          <span className="first">제7조 (개인정보 자동 수집 장치의 설치 • 운영 및 거부에 관한 사항)</span><br/>
          <span className="second">① 회사는 정보주체에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie) 등’을 사용합니다.</span>
          <span className="second">② 쿠키의 사용목적 및 설치에 관한 사항은 아래와 같습니다.</span>
          <span className="third">
            <ol>
              <li>쿠키의 사용목적: 정보주체의 방문기록 및 이용형태 등을 파악하여 정보주체에게 최적화된 맞춤 정보 제공 목적</li>
              <li>정보 주체는 아래의 설정을 통하여 쿠키를 허용하거나 거부할 수 있습니다.</li>
            </ol>
          </span>
          <span className="fourth">
            <ul>
              <li>웹페이지 상단의 도구 {'>'} 인터넷 옵션 {'>'} 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장 거부 가능, 인터넷 옵션>일반 메뉴 설정을 통해 쿠키 삭제</li>
              <li>앱 정보 {'>'} 저장공간 {'>'} 캐시 삭제</li>
            </ul>
          </span>
          <span className="third">
            <ol start={3}>
              <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
            </ol>
          </span>
          <br/><br/>
          <span className="first">제8조 (개인정보 보호책임자)</span><br/>
          <span className="second">회사는 개인정보 처리에 관한 업무를 총괄하여 책임지고 개인정보 처리와 관련된 정보주체의 불만처리 및 피해구제 등을 위하여, 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</span>
          <span className="third">
            <ol>
              <li>성명 및 직책: 최유진 대표</li>
              <li>이메일 주소: soso-tech@lemoncake.co.kr</li>
              <li>전화번호: 070-8098-9642</li>
            </ol>
          </span>
          <br/><br/>
          <span className="first">제9조 (개인정보 열람청구를 접수 • 처리하는 부서)</span><br/>
          <span className="second">정보주체는 아래의 부서에 개인정보 열람청구를 할 수 있습니다. 회사는 신속히 처리되도록 노력하겠습니다.</span>
          <span className="third">
            <ol>
              <li>개인정보 열람청구 접수 • 처리 부서: 관리팀</li>
              <li>담당자: 최유진 대표</li>
              <li>연락처(전화번호 / 이메일 주소): 070-8098-9642/soso-help@lemoncake.co.kr</li>
            </ol>
          </span>
          <br/><br/>
          <span className="first">제10조 (정보주체의 권익침해에 대한 구제방법)</span><br/>
          <span className="second">정보주체는 아래의 기관에 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하거나 보다 자세한 도움이 필요하면 문의하기 바랍니다.</span>
          <span className="third">
            <ol>
              <li>개인정보침해 신고센터 (한국인터넷진흥원 운영)</li>
              <span className="third">
                <ul>
                  <li>소관업무: 개인정보 침해사실 신고 및 상담</li>
                  <li>홈페이지: https://privacy.kisa.or.kr</li>
                  <li>전화: (국번없이) 118</li>
                  <li>주소: (58342) 전남 나주시 진흥길 9 (빛가람동) 한국인터넷진흥원 개인정보침해 신고센터</li>
                </ul>
              </span>
              <li>개인정보 분쟁조정위원회</li>
              <span className="third">
                <ul>
                  <li>소관업무: 개인정보 침해 관련 분쟁조정</li>
                  <li>홈페이지: https://www.kopico.go.kr</li>
                  <li>전화: (국번없이) 1833-6972</li>
                  <li>주소: (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층</li>
                </ul>
              </span>
              <li>경찰청 사이버안전국: 182 (https://cyberbureau.police.go.kr)</li>
            </ol>
          </span>
          <br/><br/>
          <span className="first">제11조 (개인정보 처리방침 변경)</span><br/>
          <span className="second">① 본 개인정보 처리방침은 2021. 03. 02 부터 적용됩니다.</span>
          <span className="second">② 회사가 본 개인정보 처리방침을 변경하는 경우, 변경내용 시행 7일 전부터 정보주체가 알 수 있도록 변경 전후의 내용을 비교하여 공지합니다.</span>
        </div>
      </div>
    );
  }
}

export default PrivacyTerms;