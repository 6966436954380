import React, { Component } from 'react';

import './Home.css'

import MainTop from './Header';
import PromoPage from './PromoPage';
import FunctionPage from './FunctionPage';
import HelpPage from './HelpPage';
import Menu from './Menu'

class Home extends Component {
  render() {
    return (
      <div className="Home">
          <header>
            <a href="/" className="title"><h1>LEMONCAKE</h1></a>
            <Menu/>
          </header>
          <MainTop/>
          <a name="news"/><PromoPage/>
          <a name="app"/><FunctionPage/>
          <a name="about"/><HelpPage/>
          <footer>
            <div>
              <span className="ci">LEMONCAKE</span>
              <div className="ci" style={{width:"40px"}}/>
              <span className="copyrights">Copyright ©2020 LEMONCAKE All Rights Reserved.</span>
              <div style={{width:"40px"}}>
                <hr style={{border: "solid 1px #222222", height:"10px"}}/>
              </div>
              <a href="/privacy" className="privacy">개인정보처리방침</a>
            </div>
          </footer>
        </div>
    );
  }
}

export default Home;