import React, { Component } from 'react';
import './Menu.css';
import Expand_Ico from "../assets/images/btn_expand.png"
import Close_Ico from "../assets/images/btn_close.png"

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      screenWidth: window.innerWidth
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  render() {
    if (this.state.screenWidth < 800) {
      return (
        <div className="Menu">
          <a href="#;" onClick={this.openNav}><img src={Expand_Ico}/></a>
          <div id="expand_menu" className="side_menu">
            <ul className='header'>
            <li><a href="#;" className="close" onClick={this.closeNav}><img src={Close_Ico} alt="close"/></a></li>
            </ul>
            <hr className='solid'/>
            <ul>
              <li><a href="/">HOME</a></li>
              <li><a href="/#news">NEWS</a></li>
              <li><a href="/#app">APP</a></li>
              <li><a href="/#about">ABOUT</a></li>
            </ul>  
          </div>
        </div>
      );
    }
    else { 
      return (
        <div className="Menu">
              <ul className="nav_links">
                <li><a href="/">HOME</a></li>
                <li><a href="/#news">NEWS</a></li>
                <li><a href="/#app">APP</a></li>
                <li><a href="/#about">ABOUT</a></li>
              </ul>
            </div>
      );
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

  /*
   *
   */
  openNav() {
    var x = document.getElementById("expand_menu");  
    x.className += " expand";
  }

  closeNav() {
    var x = document.getElementById("expand_menu");
    x.className = "side_menu";
  }
}

export default Menu;
