import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './assets/images/soso_ci.png';
import app_store from './assets/images/ico_appstore.png';
import aos_store from './assets/images/ico_playstore.png';
import './App.css';

import Home from './main/Home';
import Privacy from './privacy/Privacy'
import Contact from './contact/Contact'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/privacy' element={<Privacy/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;