import React, { Component } from 'react';
import './FunctionPage.css';
import app_store from '../assets/images/ico_appstore.png';
import aos_store from '../assets/images/ico_playstore.png';
import FunctionCell from "./FunctionCell"

class FunctionPage extends Component {
  constructor(props) {
    super(props);

    this.pageData = [
      {
        url:"..",
        icon:require('../assets/images/ico_home.png'),
        bg:require('../assets/images/bg_home.png'),
        title:"소소",
      },
      {
        url:"..",
        icon:require('../assets/images/ico_shop.png'),
        bg:require('../assets/images/bg_shop.png'),
        title:"동네업체",
      },
      {
        url:"..",
        icon:require('../assets/images/ico_community.png'),
        bg:require('../assets/images/bg_community.png'),
        title:"커뮤니티",
      },
      {
        url:"..",
        icon:require('../assets/images/ico_chatting.png'),
        bg:require('../assets/images/bg_chatting.png'),
        title:"채팅",
      },
      {
        url:"..",
        icon:require('../assets/images/ico_store.png'),
        bg:require('../assets/images/bg_store.png'),
        title:"스토어",
      },
      {
        url:"..",
        icon:require('../assets/images/ico_blog.png'),
        bg:require('../assets/images/bg_blog.png'),
        title:"블로그",
      },
    ];

    this.state = {
      scroll : 0,
      scrollOffset : 0,
      anim : true,
      scrolling : false,
    }
    
    this.selectPage   = this.selectPage.bind(this);
    this.pageLeft     = this.pageLeft.bind(this);
    this.pageRight    = this.pageRight.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    
    this.startX = 0;
    this.startY = 0;

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  render() {
    this.pageWidth    = window.innerWidth * 0.18;
    this.pageWidth    = Math.min(140, this.pageWidth);

    var transition = "none";
    if (this.state.anim) 
      transition = "all 0.2s ease 0s";

    var startOffset = Math.round(this.state.scroll / this.pageWidth - 5) % this.pageData.length;
    if (startOffset < 0)
      startOffset = this.pageData.length + (startOffset % this.pageData.length);  

    return (
      <div className="FunctionPage">
        <div className="container" style={{marginBottom:"5vmin"}}>
          <div >
            <img id="bg" className="sshot" src={this.pageData[0].bg} alt="function" />  
          </div>
          <div style={{margin:"3vw"}}/>
          <div className="info">
            <div className="title"><strong>소소</strong>한 집수리</div>
            <hr className='light' style={{marginTop:"5px", marginBottom:"15px"}}/>
            <pre className="description">
              소소는 우리 동네에서 집수리나 인테리어가{"\n"}
              필요한 분들과 도움을 줄 수 있는 분들이{"\n"} 
              서로 이야기하고 소통하며, 도움을 주고{"\n"}
              받을 수 있는 서비스 입니다.
            </pre>
            <div style={{margin:"5vh"}}/>
            <div className="store">
              <div><img style={{cursor:"pointer", width:"100%", borderRadius:"9px", border:"solid 1px #dddddd"}} src={app_store} alt="APPSTORE" onClick={function() {
                window.open('itms-apps://itunes.apple.com/app/id1582049413', '_blank').focus();
              }}/>
              </div>
              <div style={{margin:"1vh"}}/>
              <div><img style={{cursor:"pointer", width:"100%", borderRadius:"9px", border:"solid 1px #dddddd"}} src={aos_store} alt="GOOGLE PLAYSTORE" onClick={function() {
                window.open('market://details?id=com.lemoncake.sosofix', '_blank').focus();
              }}/></div>
            </div>
          </div>
        </div>
        <div className="scroll_panel">
          <button style={{cursor:"pointer", backgroundColor:"transparent", border: "none", position:'relative', marginRight:"10px"}} onClick={this.pageLeft}>
              <img src={require('../assets/images/btn_move_left.png')}/>
          </button>
          <hr style={{border: "solid double 1px gray", height:"10vmin"}}/>
          <div className="clipping_panel">
            <div id="panel" onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
            className="scroll_view" style={{left: this.state.scrollOffset - this.pageWidth * 5, WebkitTransition:transition}}> 
              <FunctionCell data={this.pageData[startOffset]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 1) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 2) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 3) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 4) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 5) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 6) % this.pageData.length]} onSelect={this.selectPage}/>              
              <FunctionCell data={this.pageData[(startOffset + 7) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 8) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 9) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 10) % this.pageData.length]} onSelect={this.selectPage}/>
              <FunctionCell data={this.pageData[(startOffset + 11) % this.pageData.length]} onSelect={this.selectPage}/>              
              <FunctionCell data={this.pageData[(startOffset + 12) % this.pageData.length]} onSelect={this.selectPage}/>              
            </div>
          </div>
          <hr style={{border: "solid double 1px gray", height:"10vmin"}}/>
          <button style={{cursor:"pointer", backgroundColor:"transparent", border: "none", position:'relative', marginLeft:"10px"}} onClick={this.pageRight}>
              <img src={require('../assets/images/btn_move_right.png')}/>
          </button>
        </div>
      </div>
    );
  }

  async pageLeft() {
    if (this.state.scrolling)
      return;
      
    const current = this.state.scroll + this.pageWidth
    this.setState({scrollOffset: -this.pageWidth, anim: true, scrolling: true});

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300);

    this.setState({scroll: current, scrollOffset: 0, anim: false, scrolling: false})
  }

  async pageRight() {
    if (this.state.scrolling)
      return;

    const current = this.state.scroll - this.pageWidth
    this.setState({scrollOffset: this.pageWidth, anim: true, scrolling: true});

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300);

    this.setState({scroll: current, scrollOffset: 0, anim: false, scrolling: false})
  }

  selectPage(page) {
    //this.setState({currentVidId: video.videoId})
    //console.log(page.title);
    
    const id = window.document.getElementById("bg");
    if (id != null)
      id.src = page.bg;
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    //document.addEventListener("touchstart", this.setEventTouch);
    //document.addEventListener("touchmove", this.setEventTouch);
    //document.addEventListener("touchend", this.setEventTouch);
    //document.addEventListener("touchcancel", this.setEventTouch);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    //document.removeEventListener("touchstart", this.setEventTouch);
    //document.removeEventListener("touchmove", this.setEventTouch);
    //document.removeEventListener("touchend", this.setEventTouch);
    //document.removeEventListener("touchcancel", this.setEventTouch);
  }

  updateWindowDimensions() {
    this.setState({ scroll: 0, scrollOffset: 0 });
  }

  onTouchStart(e) {
    this.startX = e.changedTouches[0].pageX;
    this.startY = e.changedTouches[0].pageY;
  }

  onTouchMove(e) {
    const divOverlay = document.getElementById("panel");

    var deltaX = e.changedTouches[0].pageX - this.startX;
    var deltaY = e.changedTouches[0].pageY - this.startY;

    this.setState({scrollOffset:this.state.scrollOffset + deltaX});

    this.startX = e.changedTouches[0].pageX;
    this.startY = e.changedTouches[0].pageY;
  }

  async onTouchEnd(e) {
    const current = Math.round(this.state.scrollOffset / this.pageWidth) * this.pageWidth;
    this.setState({scrollOffset: current, anim: true, scrolling: true});

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    await sleep(300);

    console.log(current);
    this.setState({scroll: this.state.scroll - current, scrollOffset: 0, anim: false, scrolling: false})
  }
}

export default FunctionPage;