import React, { Component } from 'react';
import './HelpPage.css';

class HelpPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      screenWidth: window.innerWidth
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  render() {
    if (this.state.screenWidth < 800) {
      return (
        <div className="HelpPage">
          <div>
            <AboutPage/>
            <ContactPage/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="HelpPage">
          <div className="container">
            <div/>
              <div id="about"><h2 className="title">ABOUT</h2></div>
              <div id="contact"><h2 className="title">CONTACT</h2></div>
            <div/>
            <div/>
            <div className="thumb">
              <img style={{clipPath: "inset(0% 0% 0% 0% round 8px)"}} src={require('../assets/images/bg_about01.jpg')} alt='제휴'/>    
            </div>
            <div className="thumb">
              <img style={{clipPath: "inset(0% 0% 0% 0% round 8px)"}} src={require('../assets/images/bg_puzzle01.jpg')} alt='제휴'/>    
            </div>
            <div/>

            <div/>
            <span className="header"><strong>집수리 문제를 해결하기 가장 좋은 플랫폼!</strong></span>
            <span className="header"><strong>LEMONCAKE와 비즈니스 제휴를 원하신다면?</strong></span>
            <div/>

            <div/>
            <pre className="content">
              LEOMONCAKE는 집수리에서 발생하는 문제들을 해결하기 위한 {"\n"}
              다양한 서비스들을 준비중에 있습니다. 국내시장을 넘어 세계로 {"\n"}
              나아가는 그날까지 노력하는 소소가 되겠습니다. 
            </pre>

            <pre className="content"> 
              최고의 사람들과 최상의 경험을 만들어 갈 수 있는 LEMONCAKE! {"\n"}
              비즈니스 제휴를 통해, 함께 성장할 수 있는 최고의 파트너가 될 수 {"\n"}
              있도록 하겠습니다.
            </pre>
            <div/>

            <div/>
            <div><span id="readMore" onClick={function() { alert("현재 페이지 공사중입니다.", "_self"); }}>READ MORE</span></div>
            <div><span id="readMore" onClick={function() { window.open('/contact', "_self"); }}>READ MORE</span></div>
            <div/>
            
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }
}

class AboutPage extends Component {

  render() {
    return (
      <div className="container" id="about">
        <div><h2 className="title">ABOUT</h2></div>

        <div className="thumb">
          <img style={{width:"100%", clipPath: "inset(0% 0% 0% 0% round 6px)"}}  src={require('../assets/images/bg_about01.jpg')} alt='About'/>
        </div>
        
        <span className="header">집수리 문제를 가장 효율적으로 풀어내는 플랫폼!</span>
        <pre className="content">
          LEOMONCAKE는 항상 진지한 자세로 집수리에서 발생하는 문제를 {"\n"}
          해결하기 위해 오늘도 노력하고 있습니다. 국내시장을 넘어 세계로 {"\n"}
          나아가는 그날까지 노력하는 소소가 되겠습니다. 
        </pre>

        <span id="readMore" onClick={function() { alert("현재 페이지 공사중입니다."); }}>READ MORE</span>
      </div>
    );
  }
}

class ContactPage extends Component {

  render() {
    return (
      <div className="container" id="contact">
        <div><h2 className="title">CONTACT</h2></div>
        
        <div className="thumb">
          <img style={{width:"100%", clipPath: "inset(0% 0% 0% 0% round 6px)"}}  src={require('../assets/images/bg_puzzle01.jpg')} alt='Contact'/>
        </div>
        
        <span className="header">LEMONCAKE와 비즈니스 제휴를 원하신다면?</span>
        <pre className="content"> 
          최고의 사람들과 최상의 경험을 만들어 갈 수 있는 LEMONCAKE! {"\n"}
          비즈니스 제휴를 통해, 함께 성장할 수 있는 최고의 파트너가 될 수 {"\n"}
          있도록 하겠습니다.
        </pre>

        <span id="readMore" onClick={function() {  window.open('/contact'); }}>READ MORE</span>
      </div>
    );
  }

}

export default HelpPage;